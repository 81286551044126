import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    useNotify,
    useRefresh,
    useRedirect,
} from "react-admin";
import MonacoEditor from "../utils/MonacoEditor";

const ReferralCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Referral saved`);
        redirect("/data-management/referrals");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    return (
        <Create title="Create a Referral" {...props} onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>

                <ArrayInput source="referralHotJobs" isRequired>
                    <SimpleFormIterator>
                        <TextInput label="Name" source="name"/>
                        <TextInput label="City" source="city"/>
                        <TextInput label="Salary" source="salary"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <MonacoEditor source="howItWorks" height={200} />

                <ArrayInput source="referralProgramRules" isRequired>
                    <SimpleFormIterator>
                        <TextInput label="Point" source="name"/>
                        <TextInput label="Picture link" source="link"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="referralProgramScheme" isRequired>
                    <SimpleFormIterator>
                        <TextInput label="Level" source="level"/>
                        <TextInput label="Salary" source="salary"/>
                        <TextInput label="Description" source="description"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <TextInput label="Description cv block" source="sampleCVBlock.description"/>
                <ArrayInput source="sampleCVBlock.sampleCVs" isRequired>
                    <SimpleFormIterator>
                        <TextInput label="Name" source="name"/>
                        <TextInput label="Link" source="link"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <TextInput label="Description who to connect block" source="whoToConnectBlock.description"/>
                <ArrayInput source="whoToConnectBlock.whoToConnects" isRequired>
                    <SimpleFormIterator>
                        <TextInput label="Name" source="name"/>
                        <TextInput label="Link" source="link"/>
                    </SimpleFormIterator>
                </ArrayInput>

            </SimpleForm>
        </Create>
    );
};

export default ReferralCreate;
