import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    useNotify,
    useRefresh,
    useRedirect,
} from "react-admin";
import Cache from "../utils/Cache";
import LexicalEditor from "../utils/LexicalEditor";

const HelpCardItemCreate = (props) => {
    const allHelpStatus = Cache.get("allHelpStatus");

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Help card item saved`);
        redirect("/data-management/help-card-items");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    return (
        <Create title="Create a help card item" {...props} onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="name" fullWidth variant="outlined" />
                <TextInput source="description" multiline fullWidth variant="outlined" />
                <LexicalEditor source="content" height={800} />
                <SelectInput
                    source="helpCardId"
                    choices={allHelpStatus}
                    resettable
                    variant="outlined"
                />
            </SimpleForm>
        </Create>
    );
};

export default HelpCardItemCreate;
