import * as Keycloak from "keycloak-js";
const { KEYCLOAK_INIT_URL, KEYCLOAK_INIT_REALM, KEYCLOAK_INIT_CLIENT_ID, KEYCLOAK_INIT_ON_LOAD } = require("./Constants");

const initOptions = {
    url: KEYCLOAK_INIT_URL,
    realm: KEYCLOAK_INIT_REALM,
    clientId: KEYCLOAK_INIT_CLIENT_ID,
    onLoad: KEYCLOAK_INIT_ON_LOAD,
};

const keycloak = Keycloak(initOptions);

keycloak.init({ onLoad: initOptions.onLoad });

export default keycloak;
