import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { $isListNode, ListNode } from "@lexical/list";
import { createPortal } from "react-dom";
import { $isHeadingNode } from "@lexical/rich-text";
import { withStyles } from "@mui/styles";

import uiTheme from '../../themes/uiTheme'
import useModal from '../../hooks/useModal'
import { InsertImageDialog } from '../../plugins/ImagesPlugin'
import { dividerStyles, toolbarStyles } from '../../insideStyles'

import FloatingLinkEditor from "./FloatingLinkEditor";
import InsertDropDown from "./InsertDropDown";
import TextFormatsDropDown from "./TextFormatsDropDown";
import { LOW_PRIORITY } from "./constants";
import { getSelectedNode } from './utils'

const supportedBlockTypes = new Set([
  "paragraph",
  "quote",
  "h1",
  "h2",
  "h3",
  "h4",
  "ul",
  "ol"
]);

const blockTypeToBlockName = {
  h1: "Heading 1",
  h2: "Heading 2",
  h3: "Heading 3",
  h4: "Heading 4",
  ul: "Bulleted List",
  ol: "Numbered List",
  paragraph: "Paragraph",
  quote: "Quote",
};

const Divider = withStyles(dividerStyles, {
  defaultTheme: uiTheme,
})(({ classes }) => {
  return <div className={classes.divider} />;
})

const ToolbarPlugin = ({ classes }) => {
  const [editor] = useLexicalComposerContext();
  const textFormatBtnRef = useRef(null);
  const insertBtnRef = useRef(null);
  const [modal, showModal] = useModal();
  const [blockType, setBlockType] = useState("paragraph");
  const [showTextFormatsDropDown, setShowTextFormatsDropDown] = useState(false);
  const [showInsertDropDown, setShowInsertDropDown] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        // setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      // setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LOW_PRIORITY
      ),
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <>
      <div className={classes.toolbar}>
        {supportedBlockTypes.has(blockType) && (
          <>
            <button
              className={classes.toolbarItem}
              onClick={() => setShowTextFormatsDropDown(!showTextFormatsDropDown)}
              aria-label="Formatting Options"
              type="button"
              ref={textFormatBtnRef}
            >
              <span className={"icon " + blockType} />
              <span className="text">{blockTypeToBlockName[blockType]}</span>
              <i className="chevron-down" />
            </button>
            {showTextFormatsDropDown &&
              createPortal(
                <TextFormatsDropDown
                  editor={editor}
                  blockType={blockType}
                  buttonRef={textFormatBtnRef}
                  setShowTextFormatsDropDown={setShowTextFormatsDropDown}
                />,
                document.body
              )}
            <Divider />
          </>
        )}
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
          }}
          className={`${classes.toolbarItem} ${isBold ? "active" : ""}`}
          aria-label="Format Bold"
          type="button"
        >
          <i className="format bold" />
        </button>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
          }}
          className={`${classes.toolbarItem} ${isItalic ? "active" : ""}`}
          aria-label="Format Italics"
          type="button"
        >
          <i className="format italic" />
        </button>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
          }}
          className={`${classes.toolbarItem} ${isUnderline ? "active" : ""}`}
          aria-label="Format Underline"
          type="button"
        >
          <i className="format underline" />
        </button>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
          }}
          className={`${classes.toolbarItem} ${isStrikethrough ? "active" : ""}`}
          aria-label="Format Strikethrough"
          type="button"
        >
          <i className="format strikethrough" />
        </button>
        <button
          onClick={insertLink}
          className={`${classes.toolbarItem} ${isLink ? "active" : ""}`}
          aria-label="Insert Link"
          type="button"
        >
          <i className="format link" />
        </button>
        {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
        <Divider />
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
          }}
          className={classes.toolbarItem}
          aria-label="Left Align"
          type="button"
        >
          <i className="format left-align" />
        </button>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
          }}
          className={classes.toolbarItem}
          aria-label="Center Align"
          type="button"
        >
          <i className="format center-align" />
        </button>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
          }}
          className={classes.toolbarItem}
          aria-label="Right Align"
          type="button"
        >
          <i className="format right-align" />
        </button>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
          }}
          className={classes.toolbarItem}
          aria-label="Justify Align"
          type="button"
        >
          <i className="format justify-align" />
        </button>
        <Divider />
        <button
          className={classes.toolbarItem}
          onClick={() => setShowInsertDropDown(!showInsertDropDown)}
          aria-label="Insert Options"
          type="button"
          ref={insertBtnRef}
        >
          <span className={"icon plus"} />
          <span className="text">Insert</span>
          <i className="chevron-down" />
        </button>
        {showInsertDropDown &&
          createPortal(
            <InsertDropDown
              editor={editor}
              buttonRef={insertBtnRef}
              setShowInsertDropDown={setShowInsertDropDown}
              onImageItemClick={() => {
                showModal('Insert Image', (onClose) => (
                  <InsertImageDialog editor={editor} onClose={onClose} />
                ))
                setShowInsertDropDown(false);
              }}
            />,
            document.body
        )}
      </div>
      
      {modal}
    </>
  );
}

export default withStyles(toolbarStyles, {
  defaultTheme: uiTheme,
})(ToolbarPlugin)
