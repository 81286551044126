import React from "react";
import {
    List,
    Datagrid,
    DateField,
    EmailField,
    ShowButton,
    TopToolbar,
    NumberField,
    FilterButton,
    SelectInput,
    SelectField,
} from "react-admin";

import Cache from "../utils/Cache";

const { FEEDBACK_CATEGORY } = require("../utils/Constants");

const FeedbackList = (props) => {
    const allOffices = Cache.get("allOffices");
    const allCities = Cache.get("allCities");
    return (
        <List
            {...props}
            title="List of feedbacks"
            actions={<ListActions />}
            bulkActionButtons={<ListBulkactionsButtons />}
            filters={titleFilters(allCities)}
        >
            <Datagrid>
                <NumberField source="id" />
                <SelectField source="title" choices={FEEDBACK_CATEGORY} label="Title" />
                <EmailField source="sender" emptyText="anonymous" />
                <SelectField source="town" choices={allCities} label="Town" />
                <SelectField source="office" choices={allOffices} label="Office" />
                <DateField source="createdDate" showTime />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

const titleFilters = (allCities) => {
    return [
        <SelectInput
            source="title"
            label="Category"
            variant="outlined"
            choices={FEEDBACK_CATEGORY}
            allowEmpty
            emptyText="Все категории"
            emptyValue={undefined}
        />,
        <SelectInput
            source="town"
            label="Town"
            variant="outlined"
            choices={allCities}
            allowEmpty
            emptyText="Все города"
            emptyValue={undefined}
        />,
    ];
};

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
    </TopToolbar>
);

const ListBulkactionsButtons = () => {
    return null;
};

export default FeedbackList;

