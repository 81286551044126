export const LTR_STYLE_NAME = 'ltr'
export const RTL_STYLE_NAME = 'rtl'

export const H1_STYLE_NAME = 'h1'
export const H2_STYLE_NAME = 'h2'
export const H3_STYLE_NAME = 'h3'
export const H4_STYLE_NAME = 'h4'
export const BODY_BIG_TEXT_STYLE_NAME = 'bodyBig'
export const BODY_MEDIUM_TEXT_STYLE_NAME = 'bodyMedium'
export const BODY_SMALL_TEXT_STYLE_NAME = 'bodySmall'
export const INFO_MEDIUM_TEXT_STYLE_NAME = 'infoMedium'
export const INFO_SMALL_TEXT_STYLE_NAME = 'infoSmall'
export const SMALL_TEXT_STYLE_NAME = 'small'
export const QUOTE_TEXT_STYLE_NAME = 'quote'

export const BOLD_TEXT_STYLE_NAME = 'bold'
export const ITALIC_TEXT_STYLE_NAME = 'italic'
export const OVERFLOWED_TEXT_STYLE_NAME = 'overflowed'
export const HASHTAG_TEXT_STYLE_NAME = 'hashtag'
export const UNDERLINE_TEXT_STYLE_NAME = 'underline'
export const STRIKETHROUGH_TEXT_STYLE_NAME = 'strikethrough'
export const UNDERLINE_STRIKETHROUGH_TEXT_STYLE_NAME = 'underlineStrikethrough'

export const LINK_STYLE_NAME = 'link'

export const NESTED_LISTITEM_STYLE_NAME = 'nested-listitem'
export const LIST_OL_STYLE_NAME = 'list-ol'
export const LIST_UL_STYLE_NAME = 'list-ul'
export const LISTITEM_STYLE_NAME = 'listitem'