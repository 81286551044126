import React from "react";
import {
    CheckboxGroupInput,
    Create,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useNotify,
    useRedirect,
    useRefresh,
} from "react-admin";
import Cache from "../utils/Cache";
import MonacoEditor from "../utils/MonacoEditor";

const BonusDiscountCreate = (props) => {
    const allBonusCategories = Cache.get("allBonusCategories");
    const allBonusCities = Cache.get("getAllBonusCitiesForCreate");

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Bonus discount saved`);
        redirect("/data-management/bonus-discounts");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, {type: "warning"});
    };

    return (
        <Create title="Create a bonus discount" {...props} onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="name" fullWidth variant="outlined"/>
                <TextInput source="numberDiscount" fullWidth variant="outlined"/>
                <TextField label="Address"/>
                <MonacoEditor source="address" height={100}/>
                <TextField label="Date title"/>
                <MonacoEditor source="dateTitle" height={100}/>
                <TextInput source="instagram" fullWidth variant="outlined"/>
                <TextField label="Instruction"/>
                <MonacoEditor source="instruction" height={200}/>
                <TextInput source="moreInformation" fullWidth variant="outlined"/>
                <TextInput source="phone" fullWidth variant="outlined"/>
                <CheckboxGroupInput
                    source="bonusCityIds"
                    choices={allBonusCities}
                    row={false}
                />
                <SelectInput
                    source="bonusCategoryId"
                    choices={allBonusCategories}
                    resettable
                    variant="outlined"
                />
            </SimpleForm>
        </Create>
    );
};

export default BonusDiscountCreate;
