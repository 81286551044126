import React from "react";
import { Show, SimpleShowLayout, RichTextField, EmailField, SelectField } from "react-admin";
import Cache from "../utils/Cache";

const { FEEDBACK_CATEGORY } = require("../utils/Constants");

const FeedbackShow = (props) => {
    const allOffices = Cache.get("allOffices");
    const allCities = Cache.get("allCities");

    return (
        <Show title="Show feedback" {...props}>
            <SimpleShowLayout>
                <EmailField multiline="true" source="sender" emptyText="anonymous" label="" />
                <span>
                    <SelectField source="town" choices={allCities} optionText="name" label="" />
                    <span>, </span>
                    <SelectField source="office" choices={allOffices} label="" />
                </span>
                <SelectField source="title" choices={FEEDBACK_CATEGORY} label="" />
                <RichTextField source="description" label="" />
            </SimpleShowLayout>
        </Show>
    );
};

export default FeedbackShow;

