import keycloak from "../components/utils/Keycloak";
const {
    REDIRECT_AFTER_LOGOUT_URL,
    AVATAR_URL,
    registerValidToken,
    refreshToken,
} = require("../components/utils/Constants");

const authProvider = {
    login: () => {
        // It used by default login page that already overriden
        // with custom. But interface should contain this method.
    },
    logout: () => {
        if (keycloak.authenticated) {
            return keycloak.logout({ redirectUri: REDIRECT_AFTER_LOGOUT_URL });
        }

        return Promise.resolve();
    },
    getIdentity: () => {
        const fullName = keycloak.tokenParsed.name;
        const avatar = AVATAR_URL;
        return Promise.resolve({ fullName, avatar });
    },
    checkAuth: () => {
        if (keycloak.authenticated) {
            return Promise.resolve();
        } else {
            keycloak
                .updateToken(70)
                .then((refreshed) => {
                    if (refreshed) {
                        console.debug("Token refreshed" + refreshed);
                        refreshToken(keycloak.token);
                        return Promise.resolve();
                    } else {
                        console.warn(
                            "Token not refreshed, valid for " +
                                Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
                                " seconds"
                        );
                        return Promise.reject();
                    }
                })
                .catch(() => {
                    console.error("Failed to refresh token");
                    return Promise.reject();
                });
        }
    },
    getPermissions: () => {
        const roles = keycloak.tokenParsed.realm_access.roles;
        return roles ? Promise.resolve(roles) : Promise.reject();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401) {
            return authProvider.logout();
        } else if (status === 404) {
            return Promise.reject({ redirectTo: "/not-found", logoutUser: false, message: false });
        } else if (status === 409) {
            registerValidToken(keycloak.token);
            window.location.reload();
        }
        return Promise.resolve();
    },
};

export default authProvider;
