import React from "react";
import {
    Edit,
    SimpleForm,
    DateTimeInput,
    TextInput,
    SelectInput,
    useNotify,
    useRefresh,
    useRedirect,
} from "react-admin";
import Cache from "../utils/Cache";

const QuestionnaireEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Questionnaire edited`);
        redirect("/data-management/questionnaires");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    const allCities = Cache.get("allCities");

    return (
        <Edit title="Edit a questionnaire" {...props} mutationMode="pessimistic" onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="id" disabled variant="outlined" />
                <TextInput source="title" multiline fullWidth variant="outlined" />
                <SelectInput
                    source="town"
                    choices={allCities}
                    allowEmpty
                    emptyText="Все города"
                    emptyValue={undefined}
                    resettable
                    variant="outlined"
                />
                <TextInput source="link" multiline fullWidth variant="outlined" required/>
                <DateTimeInput source="deadlineDate" variant="outlined" required/>
            </SimpleForm>
        </Edit>
    );
};

export default QuestionnaireEdit;
