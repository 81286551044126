import React, { useEffect, useRef } from "react"
import { $isRangeSelection, $createParagraphNode, $getSelection } from "lexical"
import { $wrapNodes } from "@lexical/selection"
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list"
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text"
import { withStyles } from "@mui/styles"

import uiTheme from '../../themes/uiTheme'
import { dropDownStyles } from '../../insideStyles'

const TextFormatsDropDown = ({
  editor,
  blockType,
  buttonRef,
  setShowTextFormatsDropDown,
  classes
}) => {
  const dropDownRef = useRef(null)

  useEffect(() => {
    const button = buttonRef.current
    const dropDown = dropDownRef.current

    if (button !== null && dropDown !== null) {
      const { top, left } = button.getBoundingClientRect()
      dropDown.style.top = `${top + window.scrollY + button.clientHeight}px`
      dropDown.style.left = `${left}px`
    }
  }, [dropDownRef, buttonRef])

  useEffect(() => {
    const dropDown = dropDownRef.current
    const button = buttonRef.current

    if (dropDown !== null && button !== null) {
      const handle = (event) => {
        const target = event.target

        if (!dropDown.contains(target) && !button.contains(target)) {
          setShowTextFormatsDropDown(false)
        }
      }
      document.addEventListener("click", handle)

      return () => {
        document.removeEventListener("click", handle)
      }
    }
  }, [dropDownRef, setShowTextFormatsDropDown, buttonRef])

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode())
        }
      })
    }
    setShowTextFormatsDropDown(false)
  }

  const formatH1 = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"))
        }
      })
    }
    setShowTextFormatsDropDown(false)
  }

  const formatH2 = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"))
        }
      })
    }
    setShowTextFormatsDropDown(false)
  }

  const formatH3 = () => {
    if (blockType !== "h3") {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h3"))
        }
      })
    }
    setShowTextFormatsDropDown(false)
  }

  const formatH4 = () => {
    if (blockType !== "h4") {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h4"))
        }
      })
    }
    setShowTextFormatsDropDown(false)
  }

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND)
    }
    setShowTextFormatsDropDown(false)
  }

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND)
    }
    setShowTextFormatsDropDown(false)
  }

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode())
        }
      })
    }
    setShowTextFormatsDropDown(false)
  }

  return (
    <div className={classes.dropdown} ref={dropDownRef}>
      <button className={`item ${blockType === "paragraph" ? 'active' : ''}`} onClick={formatParagraph}>
        <span className="icon paragraph" />
        <span className="text">Paragraph</span>
      </button>
      <button className={`item ${blockType === "h1" ? 'active' : ''}`} onClick={formatH1}>
        <span className="icon h1" />
        <span className="text">Heading 1</span>
      </button>
      <button className={`item ${blockType === "h2" ? 'active' : ''}`} onClick={formatH2}>
        <span className="icon h2" />
        <span className="text">Heading 2</span>
      </button>
      <button className={`item ${blockType === "h3" ? 'active' : ''}`} onClick={formatH3}>
        <span className="icon h3" />
        <span className="text">Heading 3</span>
      </button>
      <button className={`item ${blockType === "h4" ? 'active' : ''}`} onClick={formatH4}>
        <span className="icon h4" />
        <span className="text">Heading 4</span>
      </button>
      <button className={`item ${blockType === "ul" ? 'active' : ''}`} onClick={formatBulletList}>
        <span className="icon bullet-list" />
        <span className="text">Bullet List</span>
      </button>
      <button className={`item ${blockType === "ol" ? 'active' : ''}`} onClick={formatNumberedList}>
        <span className="icon numbered-list" />
        <span className="text">Numbered List</span>
      </button>
      <button className={`item ${blockType === "quote" ? 'active' : ''}`} onClick={formatQuote}>
        <span className="icon quote" />
        <span className="text">Quote</span>
      </button>
    </div>
  )
}

export default withStyles(dropDownStyles, {
  defaultTheme: uiTheme,
})(TextFormatsDropDown)