import {CreateButton, TopToolbar} from "react-admin";
import React from "react";
import moment from "moment";
import {DateField} from "react-admin";

export const CustomDateField = (props) => {
    const recordWithFormatDate = {
        [props.source]: moment.utc(props.record[props.source]).local().format('YYYY-MM-DD HH:mm:ss')
    };
    return <DateField {...props} record={recordWithFormatDate} />
};