import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect, SelectInput, TextField,
} from "react-admin";
import Cache from "../utils/Cache";
import MonacoEditor from "../utils/MonacoEditor";

const BonusDiscountEdit = (props) => {
    const allBonusCategories = Cache.get("allBonusCategories");
    const allBonusCities = Cache.get("allBonusCities");

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Bonus discount edited`);
        redirect("/data-management/bonus-discounts");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    return (
        <Edit title="Edit a bonus discount" {...props} mutationMode="pessimistic" onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="id" disabled variant="outlined" />
                <TextInput source="name" fullWidth variant="outlined" />
                <TextInput source="numberDiscount" fullWidth variant="outlined" />
                <TextField label="Address"/>
                <MonacoEditor source="address" height={100} />
                <TextField label="Date title"/>
                <MonacoEditor source="dateTitle" height={100} />
                <TextInput source="instagram" fullWidth variant="outlined" />
                <TextField label="Instruction"/>
                <MonacoEditor source="instruction" height={200} />
                <TextInput source="moreInformation" fullWidth variant="outlined" />
                <TextInput source="phone" fullWidth variant="outlined" />
                <SelectInput
                    source="bonusCityId"
                    choices={allBonusCities}
                    resettable
                    variant="outlined"
                />
                <SelectInput
                    source="bonusCategoryId"
                    choices={allBonusCategories}
                    resettable
                    variant="outlined"
                />
            </SimpleForm>
        </Edit>
    );
};

export default BonusDiscountEdit;
