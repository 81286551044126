import React, { useEffect, useMemo, useRef, useState } from "react"
import ToolbarPlugin from "./plugins/ToolbarPlugin"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { $getRoot, $createParagraphNode } from 'lexical'
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin"
import { ListItemNode, ListNode } from "@lexical/list"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { useInput, Button } from "react-admin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { withStyles } from '@mui/styles'

import AutoEmbedPlugin from "./plugins/AutoEmbedPlugin"
import AutoLinkPlugin from "./plugins/AutoLinkPlugin"
import ImagesPlugin from "./plugins/ImagesPlugin"
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin"
import YouTubePlugin from './plugins/YouTubePlugin'
import exportingStyles from "./exportingStyles"
import generateTheme from "./themes/generateTheme"
import uiTheme from './themes/uiTheme'
import { ImageNode } from './nodes/ImageNode'
import { YouTubeNode } from './nodes/YouTubeNode'
import { editorStyles } from './insideStyles'
import { generateHtmlString } from "./utils"

const generateEditorConfig = ({ classes }) => {
  const theme = generateTheme(classes)

  return {
    theme,
    onError(error) {
      throw error
    },
    nodes: [
      AutoLinkNode,
      HeadingNode,
      LinkNode,
      ListItemNode,
      ListNode,
      QuoteNode,
      ImageNode,
      YouTubeNode,
    ]
  }
}

function Editor({ onFormFieldChange, value, classes }) {   
  const [editor] = useLexicalComposerContext()
  const firstEditWasAlready = useRef(false)

  const cssString = useMemo(() => {
    const cssStyles = Object.entries(exportingStyles(uiTheme)).reduce((acc, [key, value]) => {
      acc[`.${classes[key]}`] = value
      
      return acc
    }, {})
  
    return Object.entries(cssStyles)
      .map(([selector, styles]) => {
        if (selector.split('-')[1] === 'body') {
          selector = 'body'
        }
        
        const styleString = Object.entries(styles)
          .map(([property, value]) => `${property.replace(/([A-Z])/g, (match) => '-' + match.toLowerCase())}: ${value}`)
          .join(';\n')
        return `${selector} {\n${styleString};\n}`
      })
      .join('\n\n')
  }, [classes])

  useEffect(() => {
    if (value) {
      editor.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser()
        const dom = parser.parseFromString(value, 'text/html')
        const bodyContent = dom.querySelector('body').innerHTML;
        const newValue = bodyContent.replace(/<p[^>]*?><\/p>/g, '').replace(/<br>/g, '')

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, parser.parseFromString(newValue, 'text/html'))

        // Select the root
        const root = $getRoot()

        const paragraphNode = $createParagraphNode()

        nodes.forEach((n)=> paragraphNode.append(n))
      
        root.append(paragraphNode)
      })
    }
  }, [])

  const onEditorChange = (_, editor) => {
    editor.update(() => {
      if (!firstEditWasAlready.current) {
        firstEditWasAlready.current = true
        return
      }
      const tmp = $generateHtmlFromNodes(editor, null)
      const htmlStr = generateHtmlString(cssString, tmp)

      const updatedHtmlStr = htmlStr.replace(/^<p[^>]*?><br><\/p>/, '') // clear first <p><br></p>
      onFormFieldChange(updatedHtmlStr)
    })
  }

  return (
    <div className={classes.editorContainer}>
      <ToolbarPlugin />
      <div className={classes.editorInner}>
        <RichTextPlugin
          contentEditable={<ContentEditable className={classes.editorInput} />}
          placeholder={<div className={classes.placeholder}>Enter some text...</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <AutoFocusPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <ImagesPlugin />
        <AutoEmbedPlugin />
        <YouTubePlugin />
        <OnChangePlugin onChange={onEditorChange} ignoreSelectionChange />
        <ListMaxIndentLevelPlugin maxDepth={7} />
      </div>
    </div>
  )
}

function LexicalEditor(props) {
  const {
      input: { name, onChange, ...rest },
  } = useInput(props)

  const [showPreview, setShowPreview] = useState(true)
  const handleTogglePreview = () => setShowPreview((p) => !p)

  const initialConfig = generateEditorConfig({ classes: props.classes })

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        label={`open ${showPreview ? "show" : "editor"}`}
        onClick={handleTogglePreview}
        style={{ marginBottom: "18px" }}
      />
      {showPreview ? (
        <LexicalComposer initialConfig={initialConfig}>
          <Editor onFormFieldChange={onChange} value={rest.value} classes={props.classes} />
        </LexicalComposer>
      ) : (
        <iframe
          title="title"
          srcDoc={rest.value}
          height={props.height}
          width="98%"
          style={{ padding: 10, margin: 2, border: 0 }}
        />
      )}
    </div>
  )
}

export default withStyles(editorStyles, {
  defaultTheme: uiTheme,
})(LexicalEditor)
