import React from "react";
import { Edit, SimpleForm, TextInput, useNotify, useRefresh, useRedirect, BooleanInput } from "react-admin";
import MonacoEditor from "../utils/MonacoEditor";

const VersionEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`App version edited`);
        redirect("/data-management/versions");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };
    return (
        <Edit
            title="Edit a app version"
            {...props}
            mutationMode="pessimistic"
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm>
                <TextInput source="id" disabled variant="outlined" />
                <TextInput source="version" variant="outlined" />
                <BooleanInput source="isSupport" variant="outlined" />
                <MonacoEditor source="description" height={400} />
            </SimpleForm>
        </Edit>
    );
};

export default VersionEdit;

