import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    RichTextField,
    CreateButton,
    TopToolbar,
} from "react-admin";

const BonusCategoryList = (props) => (
    <List {...props}
          title="List of bonus categories"
          actions={<ListActions />}
          sort={{ field: "createdDate", order: "DESC" }}>
        <Datagrid>
            <TextField source="id" />
            <RichTextField source="name" label="Name" />
            <RichTextField source="number" label="Number" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

export default BonusCategoryList;

