import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    SelectField,
    RichTextField,
    DeleteWithConfirmButton,
    DateField,
    Toolbar,
    ImageField, TextInput, SelectInput, DateTimeInput,
} from "react-admin";
import Cache from "../utils/Cache";

const QuestionnaireShow = (props) => {
    const allCities = Cache.get("allCities");

    return (
        <Show title="Show questionnaire" {...props}>
            <SimpleShowLayout>
                <TextField disabled source="id" />
                <TextField source="title" label="" />
                <SelectField source="town" choices={allCities} label="" />
                <TextField source="link" label="" />
                <DateField source="deadlineDate" showTime label="" />

                <Toolbar>
                    <DeleteWithConfirmButton />
                </Toolbar>
            </SimpleShowLayout>
        </Show>
    );
};

export default QuestionnaireShow;
