import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    RichTextField,
    CreateButton,
    TopToolbar,
} from "react-admin";

const BonusCityList = (props) => (
    <List {...props}
          title="List of bonus cities"
          actions={<ListActions />}
          sort={{ field: "createdDate", order: "DESC" }}>
        <Datagrid>
            <TextField source="id" />
            <RichTextField source="city" label="Name city" />
            <RichTextField source="subtitle" label="Subtitle" />
            <RichTextField source="number" label="Number" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

export default BonusCityList;

