import React from "react";
import { Create, SimpleForm, DateInput, TextInput, SelectInput, useNotify, useRefresh, useRedirect } from "react-admin";
import LexicalEditor from "../utils/LexicalEditor";

const { TAG } = require("../utils/Constants");

const NewsCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`News saved`);
        redirect("/data-management/news");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    return (
        <Create title="Create a News" {...props} onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="title" multiline fullWidth variant="outlined" />
                <TextInput source="previewImage" multiline fullWidth variant="outlined" />
                <DateInput source="publishedDate" variant="outlined" />
                <SelectInput source="tag" choices={TAG} variant="outlined" />
                <LexicalEditor source="description" height={800} />
            </SimpleForm>
        </Create>
    );
};

export default NewsCreate;

