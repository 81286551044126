import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
} from "react-admin";

const HelpCardCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Help card saved`);
        redirect("/data-management/help-cards");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    return (
        <Create title="Create a help card" {...props} onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="name" fullWidth variant="outlined" />
                <TextInput source="description" multiline fullWidth variant="outlined" />
                <TextInput source="imageUrl" multiline fullWidth variant="outlined" />
            </SimpleForm>
        </Create>
    );
};

export default HelpCardCreate;
