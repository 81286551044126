const ForbiddenPage = () => (
    <div class="box">
        <h1>403</h1>

        <p>Sorry, it's not allowed to go beyond this point!</p>
    </div>
);

export default ForbiddenPage;

