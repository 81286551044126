import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    useRecordContext,
    ArrayField, Datagrid, DeleteWithConfirmButton, Toolbar
} from "react-admin";

const ReferralShow = (props) => {
    return (
        <Show title="Show the Referral" {...props}>
            <SimpleShowLayout>

                <ArrayField source="referralHotJobs" isRequired>
                    <Datagrid>
                        <TextField label="Name" source="name"/>
                        <TextField label="City" source="city"/>
                        <TextField label="Salary" source="salary"/>
                    </Datagrid>
                </ArrayField>

                <ShowMonacoEditorBody source="howItWorks" />

                <ArrayField source="referralProgramRules" isRequired>
                    <Datagrid>
                        <TextField label="Point" source="name"/>
                        <TextField label="Picture link" source="link"/>
                    </Datagrid>
                </ArrayField>

                <ArrayField source="referralProgramScheme" isRequired>
                    <Datagrid>
                        <TextField label="Level" source="level"/>
                        <TextField label="Salary" source="salary"/>
                        <TextField label="Description" source="description"/>
                    </Datagrid>
                </ArrayField>

                <TextField label="Description cv block" source="sampleCVBlock.description"/>
                <ArrayField source="sampleCVBlock.sampleCVs" isRequired>
                    <Datagrid>
                        <TextField label="Name" source="name"/>
                        <TextField label="Link" source="link"/>
                    </Datagrid>
                </ArrayField>

                <TextField label="Description who to connect block" source="whoToConnectBlock.description"/>
                <ArrayField source="whoToConnectBlock.whoToConnects" isRequired>
                    <Datagrid>
                        <TextField label="Name" source="name"/>
                        <TextField label="Link" source="link"/>
                    </Datagrid>
                </ArrayField>

                <Toolbar>
                    <DeleteWithConfirmButton />
                </Toolbar>
            </SimpleShowLayout>
        </Show>
    );
};

const ShowMonacoEditorBody = ({ source }) => {
    const record = useRecordContext();
    return (
        <iframe
            title="title"
            srcDoc={record[source]}
            height="200"
            width="98%"
            style={{ padding: 10, margin: 2, border: 0 }}
        />
    );
};

export default ReferralShow;
