import React from "react";
import { Create, SimpleForm, TextInput, useNotify, useRefresh, useRedirect, BooleanInput } from "react-admin";
import MonacoEditor from "../utils/MonacoEditor";

const VersionCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`App version saved`);
        redirect("/data-management/versions");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };
    return (
        <Create title="Create a App version" {...props} onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="version" multiline variant="outlined" />
                <BooleanInput source="isSupport" variant="outlined" defaultValue={true} />
                <MonacoEditor source="description" height={400} />
            </SimpleForm>
        </Create>
    );
};

export default VersionCreate;

