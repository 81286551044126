import React, { useEffect, useRef } from "react";
import {INSERT_EMBED_COMMAND} from '@lexical/react/LexicalAutoEmbedPlugin';
import { withStyles } from "@mui/styles";

import uiTheme from '../../themes/uiTheme'
import { dropDownStyles } from '../../insideStyles'

const InsertDropDown = ({
  editor,
  buttonRef,
  setShowInsertDropDown,
  classes,
  onImageItemClick
}) => {
  const dropDownRef = useRef(null);

  useEffect(() => {
    const button = buttonRef.current;
    const dropDown = dropDownRef.current;

    if (button !== null && dropDown !== null) {
      const { top, left } = button.getBoundingClientRect();
      dropDown.style.top = `${top + window.scrollY + button.clientHeight}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, buttonRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const button = buttonRef.current;

    if (dropDown !== null && button !== null) {
      const handle = (event) => {
        const target = event.target;

        if (!dropDown.contains(target) && !button.contains(target)) {
          setShowInsertDropDown(false);
        }
      };
      document.addEventListener("click", handle);

      return () => {
        document.removeEventListener("click", handle);
      };
    }
  }, [dropDownRef, setShowInsertDropDown, buttonRef]);


  const handleInsertYoutubeVideoClick = () => {
    editor.dispatchCommand(INSERT_EMBED_COMMAND, 'youtube-video');
    setShowInsertDropDown(false);
  }

  return (
    <div className={classes.dropdown} ref={dropDownRef}>
      <button className='item' onClick={onImageItemClick}>
        <i className="icon image" />
        <span className="text">Image</span>
      </button>
      <button className='item' onClick={handleInsertYoutubeVideoClick}>
        <i className="icon youtube" />
        <span className="text">Youtube Video</span>
      </button>
    </div>
  );
}

export default withStyles(dropDownStyles, {
  defaultTheme: uiTheme,
})(InsertDropDown)