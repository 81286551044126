import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import keycloak from "./components/utils/Keycloak";
import Cache from "./components/utils/Cache";
import {
    getAllBonusCategories,
    getAllBonusCities,
    getAllBonusCitiesForCreate,
    getAllHelpStatus
} from "./components/utils/Constants";

const { getAllOffices, getAllCities } = require("./components/utils/Constants");

setTimeout(() => {
    if (keycloak.authenticated) {
        Cache.set("allOffices", getAllOffices(keycloak.token));
        Cache.set("allCities", getAllCities(keycloak.token));
        Cache.set("allHelpStatus", getAllHelpStatus(keycloak.token));
        Cache.set("allBonusCategories", getAllBonusCategories(keycloak.token));
        Cache.set("allBonusCities", getAllBonusCities(keycloak.token));
        Cache.set("getAllBonusCitiesForCreate", getAllBonusCitiesForCreate(keycloak.token));
        ReactDOM.render(<App />, document.getElementById("root"));
    }
}, 1000);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

