import './styles.css'

import { useEffect, useRef, useState } from 'react'
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {$wrapNodeInElement, mergeRegister} from '@lexical/utils'
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
} from 'lexical'

import ImageCompressor from '../../../ImageCompressor'
import { $createImageNode, ImageNode } from '../../nodes/ImageNode'

import { INSERT_IMAGE_COMMAND, onDragStart, onDragover, onDrop } from './utils'

export function InsertImageUriDialogBody({
  onClick,
}) {
  const [src, setSrc] = useState('')
  const [altText, setAltText] = useState('')

  const isDisabled = src === ''

  return (
    <div className='url-container'>
      <div className='input-wrapper'>
        <label>Image URL</label>
        <input
          placeholder="i.e. https://source.unsplash.com/random"
          onChange={(e) => setSrc(e.target.value)}
          value={src}
        />
      </div>
      <div className='input-wrapper'>
        <label>Alt Text</label>
        <input
          placeholder="Random unsplash image"
          onChange={(e) => setAltText(e.target.value)}
          value={altText}
        />
      </div>
      <button
        disabled={isDisabled}
        onClick={() => onClick({altText, src})}>
        Confirm
      </button>
    </div>
  )
}

const SELECT_IMAGE_FILE = 'SELECT_IMAGE_FILE'
const SELECT_IMAGE_QUALITY = 'SELECT_IMAGE_QUALITY'

export function InsertImageUploadedDialogBody({ onClick = () => {} }) {
  const [step, setStep] = useState(SELECT_IMAGE_FILE)
  const [src, setSrc] = useState('')
  const [file, setFile] = useState(null)
  const [altText, setAltText] = useState('')

  const isDisabled = src === ''

  const loadImage = (files) => {
    const reader = new FileReader()
    reader.onload = function () {
      if (typeof reader.result === 'string') {
        setSrc(reader.result)
      }
      return ''
    }
    if (files !== null && files.length > 0) {
      setFile(files[0])
      reader.readAsDataURL(files[0])
    }
  }

  return (
    <div className='url-container'>
      {step === SELECT_IMAGE_FILE && (
        <>
          <div className='input-wrapper'>
            <label>Image Upload</label>
            <input
              type="file"
              accept='image/*'
              onChange={(e) => loadImage(e.target.files)}
            />
          </div>
          <div className='input-wrapper'>
            <label>Alt Text</label>
            <input
              placeholder="Random unsplash image"
              onChange={(e) => setAltText(e.target.value)}
              value={altText}
            />
          </div>
          <button
            disabled={isDisabled}
            onClick={() => {
              setStep(SELECT_IMAGE_QUALITY)
              setSrc('')
            }}>
            Next
          </button>
        </>
      )}
      {step === SELECT_IMAGE_QUALITY && (
        <>
          <ImageCompressor file={file} setCompressedURL={setSrc} />
          <button onClick={() => setStep(SELECT_IMAGE_FILE)}>
            Back
          </button>
          <button
            disabled={isDisabled}
            onClick={() => onClick({altText, src})}>
            Confirm
          </button>
        </>
      )}
    </div>
  )
}

export function InsertImageDialog({ editor, onClose }) {
  const [mode, setMode] = useState(null)
  const hasModifier = useRef(false)

  useEffect(() => {
    hasModifier.current = false
    const handler = (e) => {
      hasModifier.current = e.altKey
    }
    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [editor])

  const onClick = (payload) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload)
    onClose()
  }

  return (
    <>
      {!mode && (
        <div className='buttons-container'>
          <button onClick={() => setMode('url')}>
            URL
          </button>
          <button onClick={() => setMode('file')}>
            File
          </button>
        </div>
      )}
      {mode === 'url' && <InsertImageUriDialogBody onClick={onClick} />}
      {mode === 'file' && <InsertImageUploadedDialogBody onClick={onClick} />}
    </>
  )
}

export default function ImagesPlugin({ captionsEnabled }) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor')
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload)
          $insertNodes([imageNode])
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd()
          }

          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        DRAGSTART_COMMAND,
        (event) => {
          return onDragStart(event)
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        DRAGOVER_COMMAND,
        (event) => {
          return onDragover(event)
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        DROP_COMMAND,
        (event) => {
          return onDrop(event, editor)
        },
        COMMAND_PRIORITY_HIGH,
      ),
    )
  }, [captionsEnabled, editor])

  return null
}
