import React from "react";
import {CreateButton, Datagrid, EditButton, List, RichTextField, ShowButton, TextField, TopToolbar,} from "react-admin";
import {CustomDateField} from "./CustomDateField";

const EventList = (props) => (
    <List {...props} title="List of events" actions={<ListActions/>} sort={{field: "eventDateTime", order: "DESC"}}>
        <Datagrid>
            <TextField source="id"/>
            <RichTextField source="title" label="Event"/>
            <CustomDateField source="eventDateTime" showTime label="Date"/>
            <EditButton/>
            <ShowButton/>
        </Datagrid>
    </List>
);

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

export default EventList;

