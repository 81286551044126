import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    RichTextField,
    DeleteWithConfirmButton,
    DateField,
    Toolbar,
    EditButton,
    ImageField,
    SelectField,
    useNotify,
    useRefresh,
    useRedirect,
    useRecordContext,
} from "react-admin";
import PublishIcon from "@mui/icons-material/Publish";
import NewsList from "./NewsList";
import keycloak from "../utils/Keycloak";

const { DATA_PROVIDER_URL, TAG } = require("../utils/Constants");

const NewsShow = (props) => {
    return (
        <Show title="Show news" {...props}>
            <SimpleShowLayout>
                <TextField disabled source="id" />
                <DateField source="publishedDate" label="" />
                <RichTextField source="title" aria-multiline fullWidth label="" />
                <SelectField source="tag" choices={TAG} label="" />
                <ImageField source="previewImage" label="" />
                <ShowNewsBody source="description" />

                <Toolbar>
                    <PublishButton />
                    <DeleteWithConfirmButton style={{ marginLeft: "auto", marginRight: 0 }} />
                </Toolbar>
            </SimpleShowLayout>
        </Show>
    );
};

const ShowNewsBody = ({ source }) => {
    const record = useRecordContext();
    return (
        <iframe
            title="title"
            srcDoc={record[source]}
            height="800"
            width="98%"
            style={{ padding: 10, margin: 2, border: 0 }}
        />
    );
};

const PublishButton = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const record = useRecordContext();
    const handleClick = () => {
        const url = `${DATA_PROVIDER_URL}/data-management/news/status/${record.id}`;
        const config = {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            },
        };
        fetch(url, config).then((responce) => {
            if (responce.ok) {
                notify(`News published`);
                redirect("/data-management/news");
                refresh();
            }
        });
    };

    if (record.newsStatus === "DRAFT") {
        return <EditButton onClick={handleClick} to={<NewsList />} label="Publish" icon={<PublishIcon />} />;
    } else {
        return null;
    }
};

export default NewsShow;

