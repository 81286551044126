import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    useNotify,
    useRefresh,
    useRedirect,
} from "react-admin";
import Cache from "../utils/Cache";
import LexicalEditor from "../utils/LexicalEditor";

const HelpCardItemEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Help card item edited`);
        redirect("/data-management/help-card-items");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    const allHelpStatus = Cache.get("allHelpStatus");

    return (
        <Edit title="Edit a help card item" {...props} mutationMode="pessimistic" onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="id" disabled variant="outlined" />
                <TextInput source="name" multiline fullWidth variant="outlined" />
                <TextInput source="description" multiline fullWidth variant="outlined" />
                <LexicalEditor source="content" height={800} />
                <TextInput source="number" variant="outlined" />
                <SelectInput
                    source="helpCardId"
                    choices={allHelpStatus}
                    resettable
                    variant="outlined"
                />
            </SimpleForm>
        </Edit>
    );
};

export default HelpCardItemEdit;
