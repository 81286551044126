import React from "react";
import {
    DateField,
    DeleteWithConfirmButton,
    ImageField,
    RichTextField,
    SelectField,
    Show,
    SimpleShowLayout,
    TextField,
    Toolbar,
} from "react-admin";
import Cache from "../utils/Cache";
import {MONTHS} from "../utils/Constants";
import {CustomDateField} from "./CustomDateField";

const {EVENT_FORMAT, TAG} = require("../utils/Constants");

const EventShow = (props) => {
    const allCities = Cache.get("allCities");

    return (
        <Show title="Show event" {...props}>
            <SimpleShowLayout>
                <TextField disabled source="id"/>
                <CustomDateField source="eventDateTime" showTime label="eventDateTime"/>
                <SelectField source="monthId" choices={MONTHS} label="month"/>
                <SelectField source="eventFormat" choices={EVENT_FORMAT} label="eventFormat"/>
                <SelectField source="tag" choices={TAG} label="tag"/>
                <TextField source="title" label="title"/>
                <RichTextField source="description" aria-multiline fullWidth label="description"/>
                <SelectField source="town" choices={allCities} label="town"/>
                <TextField source="location" label="location"/>
                <ImageField source="previewImage" label="previewImage"/>

                <Toolbar>
                    <DeleteWithConfirmButton/>
                </Toolbar>
            </SimpleShowLayout>
        </Show>
    );
};

export default EventShow;
