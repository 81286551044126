import { useEffect, useRef, useState } from "react";
import "./style.css";
import Compressor from "./compressor";

const ImageCompressor = ({ file, setCompressedURL }) => {
    const [src] = useState(URL.createObjectURL(file));

    const fileNameSpanRef = useRef();
    const oldDimensionSpanRef = useRef();
    const oldSizeSpanRef = useRef();

    const compressedSpanRef = useRef();
    const newDimensionSpanRef = useRef();
    const newSizeSpanRef = useRef();

    const inputValueRef = useRef();

    const oldImageRef = useRef();
    const newImageRef = useRef();

    const onViewSliderInput = (event) => {
        newImageRef.current.style.width = (oldImageRef.current.clientWidth / 100) * event.target.value + "px";
    };

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = function () {
            const w = img.width;
            const h = img.height;
            if (fileNameSpanRef.current) {
                fileNameSpanRef.current.innerHTML = file.name;
            }
            if (oldDimensionSpanRef.current) {
                oldDimensionSpanRef.current.innerHTML = `${w}/${h}`;
            }
            if (oldSizeSpanRef.current) {
                oldSizeSpanRef.current.innerHTML = `${(file.size / 1024).toFixed(0)}Kb`;
            }

            calculateValues(inputValueRef.current.value, w, h);
            inputValueRef.current.onchange = function () {
                calculateValues(inputValueRef.current.value, w, h);
            };
        };
    }, []);

    function calculateValues(v, w, h) {
        const outputQuality = (100 - v) / 100;
        const outputWidth = w * outputQuality;
        const outputHeight = h * outputQuality;

        new Compressor(file, {
            quality: outputQuality,
            width: outputWidth,
            height: outputHeight,
            success(result) {
                const img = new Image();
                const reader = new FileReader();

                img.src = URL.createObjectURL(result);
                reader.readAsDataURL(result);

                reader.onload = function (event) {
                    if (compressedSpanRef.current) {
                        compressedSpanRef.current.innerHTML = `${Number(v).toFixed(0)}%`;
                    }
                    if (newSizeSpanRef.current) {
                        const size = (result.size / 1024).toFixed(0);
                        newSizeSpanRef.current.innerHTML = `${size}Kb`;
                        newSizeSpanRef.current.className = size > 100 ? "error" : size > 50 ? "warning" : "success";
                    }

                    newImageRef.current.children[0].src = event.target.result;
                    setCompressedURL(event.target.result);
                };

                img.onload = function () {
                    if (newDimensionSpanRef.current) {
                        newDimensionSpanRef.current.innerHTML = `${img.width}/${img.height}`;
                    }
                };
            },
            error(err) {
                console.log(err.message);
            },
        });
    }

    return (
        <>
            <div className="container">
                <ul className="metadata">
                    <li>
                        <span>Name:</span>
                        <span ref={fileNameSpanRef} />
                    </li>
                    <li>
                        <span>Dimension:</span>
                        <span ref={oldDimensionSpanRef} />
                    </li>
                    <li>
                        <span>Size:</span>
                        <span ref={oldSizeSpanRef} />
                    </li>
                </ul>
                <ul className="metadata">
                    <li>
                        <span>Compressed:</span>
                        <span ref={compressedSpanRef} />
                    </li>
                    <li>
                        <span>Dimension:</span>
                        <span ref={newDimensionSpanRef} />
                    </li>
                    <li>
                        <span>Size:</span>
                        <span ref={newSizeSpanRef} />
                    </li>
                </ul>
            </div>
            <div className="progress">
                <input type="range" className="input-value" min="0" max="99" step="any" ref={inputValueRef} />
            </div>
            <div className="preview-container">
                <input type="range" id="slider-input" min="0" max="100" step="any" onInput={onViewSliderInput} />
                <div className="compressed">Compressed</div>
                <div className="original">Original</div>
                <div className="bottom" ref={oldImageRef}>
                    <img src={src} alt="" />
                </div>
                <div className="top" ref={newImageRef}>
                    <img alt="" />
                </div>
            </div>
        </>
    );
};

export default ImageCompressor;
