import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    RichTextField,
    CreateButton,
    FilterButton,
    TopToolbar,
    BooleanField,
    BulkDeleteButton,
    SelectInput,
    useNotify,
    useRefresh,
    useUnselectAll,
} from "react-admin";
import { Fragment } from "react";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import keycloak from "../utils/Keycloak";

const { DATA_PROVIDER_URL, IS_SUPPORT } = require("../utils/Constants");

const VersionsList = (props) => (
    <List
        {...props}
        title="List of app versions"
        actions={<ListActions />}
        bulkActionButtons={<ListBulkactionsButtons />}
        filters={isVersionSupportFilter()}
        sort={{ field: 'version', order: 'DESC' }}
    >
        <Datagrid>
            <BooleanField source="isSupport" />
            <TextField source="version" />
            <RichTextField source="description" />
            <EditButton />
        </Datagrid>
    </List>
);

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <CreateButton />
    </TopToolbar>
);

const ListBulkactionsButtons = (props) => {
    return (
        <Fragment>
            <MakeUnsupported props={props} />
            <BulkDeleteButton />
        </Fragment>
    );
};

const isVersionSupportFilter = () => {
    return [
        <SelectInput
            source="isSupport"
            label="Is support"
            choices={IS_SUPPORT}
            variant="outlined"
            allowEmpty
            emptyText="Все версии"
            emptyValue={undefined}
        />,
    ];
};

const MakeUnsupported = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll("/data-management/versions");

    const handleClick = () => {
        console.log(props);
        const url = `${DATA_PROVIDER_URL}/data-management/versions`;
        const config = {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
                "Content-type": "application/json",
            },
            body: JSON.stringify(props.props.selectedIds),
        };
        fetch(url, config).then((responce) => {
            if (responce.ok) {
                notify(`Made app versions unsupported`);
                unselectAll(props.props.resource);
                refresh();
            }
        });
    };
    return <EditButton onClick={handleClick} to={<VersionsList />} label="Make unsupported" icon={<MobileOffIcon />} />;
};

export default VersionsList;

