import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DeleteWithConfirmButton,
    Toolbar,
} from "react-admin";

const BonusCityShow = (props) => {
    return (
        <Show title="Show bonus city" {...props}>
            <SimpleShowLayout>
                <TextField disabled source="id" />
                <TextField source="city" label="City" />
                <TextField source="subtitle" label="Subtitle" />
                <TextField source="imageUrl" label="Image url" />
                <TextField source="number" label="Number" />

                <Toolbar>
                    <DeleteWithConfirmButton />
                </Toolbar>
            </SimpleShowLayout>
        </Show>
    );
};

export default BonusCityShow;
