import { createStyles } from '@mui/styles'

import ArrowClockwiseIcon from '../../../assets/images/icons/arrow-clockwise.svg'
import ArrowCounterClockwiseIcon from '../../../assets/images/icons/arrow-counterclockwise.svg'
import ChevronDownIcon from '../../../assets/images/icons/chevron-down.svg'
import CodeIcon from '../../../assets/images/icons/code.svg'
import FileImageIcon from '../../../assets/images/icons/file-image.svg'
import JustifyIcon from '../../../assets/images/icons/justify.svg'
import LinkIcon from '../../../assets/images/icons/link.svg'
import ListOlIcon from '../../../assets/images/icons/list-ol.svg'
import ListUlIcon from '../../../assets/images/icons/list-ul.svg'
import PencilIcon from '../../../assets/images/icons/pencil-fill.svg'
import PlusIcon from '../../../assets/images/icons/plus.svg'
import QuoteIcon from '../../../assets/images/icons/chat-square-quote.svg'
import TextCenterIcon from '../../../assets/images/icons/text-center.svg'
import TextH1Icon from '../../../assets/images/icons/type-h1.svg'
import TextH2Icon from '../../../assets/images/icons/type-h2.svg'
import TextH3Icon from '../../../assets/images/icons/type-h3.svg'
import TextH4Icon from '../../../assets/images/icons/type-h4.svg'
import TextLeftIcon from '../../../assets/images/icons/text-left.svg'
import TextParagraphIcon from '../../../assets/images/icons/text-paragraph.svg'
import TextRightIcon from '../../../assets/images/icons/text-right.svg'
import TypeBoldIcon from '../../../assets/images/icons/type-bold.svg'
import TypeItalicIcon from '../../../assets/images/icons/type-italic.svg'
import TypeStrikethroughIcon from '../../../assets/images/icons/type-strikethrough.svg'
import TypeUnderlineIcon from '../../../assets/images/icons/type-underline.svg'
import YoutubeIcon from '../../../assets/images/icons/youtube.svg'

import exportingStyles from './exportingStyles'

export const editorStyles =  createStyles(theme => ({
  editorContainer: {
    maxHeight: '80vh',
    margin: `${theme.spacing(3)} auto`,
    borderRadius: theme.spacing(2),
    position: 'relative',
    textAlign: 'left',
    border: `1px solid ${theme.palette.grey['15']}`,
    padding: theme.spacing(1),
    paddingTop: 0,
    overflow: 'auto',
  },
  editorInner: {
    background: theme.palette.common.white,
    position: 'relative'
  },
  editorInput: {
    minHeight: theme.spacing(20),
    resize: 'none',
    position: 'relative',
    tabSize: 1,
    outline: 0,
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
  },
  placeholder: {
    ...theme.typography.infoMedium,
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(1.5),
  },
  ...exportingStyles(theme),
}))

export const linkEditorStyles =   createStyles(theme => ({
  linkEditor: {
    position: 'absolute',
    zIndex: 100,
    marginTop: `-${theme.spacing(1)}`,
    maxWidth: theme.spacing(40),
    width: '100%',
    opacity: 0,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
    borderRadius: theme.spacing(2),
    transition: 'opacity 0.5s',

    '& .input': {
      display: 'block',
      boxSizing: 'border-box',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.grey,
      border: 0,
      outline: 0,
      position: 'relative',

      '&.editMode': {
        width: `calc(100% - ${theme.spacing(2)})`,
      },

      '& a': {
        color: theme.palette.action.main,
        textDecoration: 'none',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginRight: 30,
        textOverflow: 'ellipsis',

        '&:hover': {
          textDecoration: 'underline',
        }
      }
    },

    '& .link-edit': {
      backgroundImage: `url(${PencilIcon})`,
      backgroundSize: theme.spacing(2),
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: theme.spacing(5),
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      cursor: 'pointer',
      borderRadius: theme.spacing(1),

      '&:hover': {
        backgroundColor: theme.palette.grey['4'],
      }
    }
  }
}))

export const dividerStyles = createStyles(theme => ({
  divider: {
    width: 1,
    backgroundColor: theme.palette.grey['15'],
    margin: `0 ${theme.spacing(0.5)}` 
  },
}))

export const dropDownStyles = createStyles(theme => ({
 
  dropdown: {
    display: 'block',
    position: 'absolute',
    boxShadow: '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    inset: '0 0 0 1px rgba(255, 255, 255, 0.5)',
    borderRadius: theme.spacing(1),
    minWidth: theme.spacing(15),
    minHeight: theme.spacing(5),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    zIndex: 5,

    '& .item': {
      ...theme.typography.infoMedium,
      width: '100%',
      padding: theme.spacing(1),
      cursor: 'pointer',
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      flexDirection: 'row',
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      border: 0,

      '&.active': {
        backgroundColor: theme.palette.grey['5'],
      },

      '&:hover': {
        backgroundColor: theme.palette.grey['4']
      },

      '& .icon': {
        width: theme.spacing(2),
        height: theme.spacing(2),
        userSelect: 'none',
        backgroundSize: 'contain',
        
        '&.paragraph': {
          backgroundImage: `url(${TextParagraphIcon})`
        },
        '&.h1': {
          backgroundImage: `url(${TextH1Icon})`
        },
        '&.h2': {
          backgroundImage: `url(${TextH2Icon})`
        },
        '&.h3': {
          backgroundImage: `url(${TextH3Icon})`
        },
        '&.h4': {
          backgroundImage: `url(${TextH4Icon})`
        },
        '&.bullet-list': {
          backgroundImage: `url(${ListUlIcon})`
        },
        '&.numbered-list': {
          backgroundImage: `url(${ListOlIcon})`
        },
        '&.quote': {
          backgroundImage: `url(${QuoteIcon})`
        },
        '&.image': {
          backgroundImage: `url(${FileImageIcon})`
        },
        '&.youtube': {
          backgroundImage: `url(${YoutubeIcon})`
        }
      }
    }
  },
}))

export const toolbarStyles = createStyles(theme => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  toolbarItem: {
    border: 0,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    background: 'none',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer',

    '&.active': {
      backgroundColor: theme.palette.grey['5'],

      '& i': {
        opacity: 1,
      }
    },

    '&:disabled': {
      cursor: 'not-allowed',

      '& i.format': {
        opacity: 0.2,
      }
    },

    '&:hover:not([disabled])': {
      backgroundColor: theme.palette.grey['4'],
    },

    '& .text': {
      ...theme.typography.bodyBig,
    },

    '& .icon': {
      display: 'flex',
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
      userSelect: 'none',
      backgroundSize: 'contain',
          
      '&.paragraph': {
        backgroundImage: `url(${TextParagraphIcon})`
      },
      '&.h1': {
        backgroundImage: `url(${TextH1Icon})`
      },
      '&.h2': {
        backgroundImage: `url(${TextH2Icon})`
      },
      '&.h3': {
        backgroundImage: `url(${TextH3Icon})`
      },
      '&.h4': {
        backgroundImage: `url(${TextH4Icon})`
      },
      '&.ul': {
        backgroundImage: `url(${ListUlIcon})`
      },
      '&.ol': {
        backgroundImage: `url(${ListOlIcon})`
      },
      '&.quote': {
        backgroundImage: `url(${QuoteIcon})`
      },
      '&.plus': {
        backgroundImage: `url(${PlusIcon})`
      }
    },

    '& i': {
      '&.chevron-down': {
        backgroundSize: 'contain',
        display: 'inline-block',
        height: theme.spacing(2),
        width: theme.spacing(2),
        backgroundColor: 'transparent',
        backgroundImage: `url(${ChevronDownIcon})`
      },

      '&.format': {
        backgroundSize: 'contain',
        display: 'inline-block',
        height: theme.spacing(3),
        width: theme.spacing(3),
        opacity: 0.6,
  
        '&.undo': {
          backgroundImage: `url(${ArrowCounterClockwiseIcon})`
        },
        '&.redo': {
          backgroundImage: `url(${ArrowClockwiseIcon})`
        },    
        '&.bold': {
          backgroundImage: `url(${TypeBoldIcon})`
        },
        '&.italic': {
          backgroundImage: `url(${TypeItalicIcon})`
        },
        '&.underline': {
          backgroundImage: `url(${TypeUnderlineIcon})`
        },
        '&.strikethrough': {
          backgroundImage: `url(${TypeStrikethroughIcon})`
        },
        '&.code': {
          backgroundImage: `url(${CodeIcon})`
        },
        '&.link': {
          backgroundImage: `url(${LinkIcon})`
        },
        '&.left-align': {
          backgroundImage: `url(${TextLeftIcon})`
        },
        '&.center-align': {
          backgroundImage: `url(${TextCenterIcon})`
        },
        '&.right-align': {
          backgroundImage: `url(${TextRightIcon})`
        },
        '&.justify-align': {
          backgroundImage: `url(${JustifyIcon})`
        },
      }
    }
  },
}))
