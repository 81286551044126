import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DeleteWithConfirmButton,
    Toolbar, useRecordContext,
} from "react-admin";

const HelpCardItemShow = (props) => {
    return (
        <Show title="Show help card item" {...props}>
            <SimpleShowLayout>
                <TextField disabled source="id" />
                <TextField source="name" label="Name" />
                <TextField source="description" label="Description" />
                <ShowHelpCardItemBody source="content" />
                <TextField source="helpCardId" label="HelpCardId" />

                <Toolbar>
                    <DeleteWithConfirmButton />
                </Toolbar>
            </SimpleShowLayout>
        </Show>
    );
};

const ShowHelpCardItemBody = ({ source }) => {
    const record = useRecordContext();
    return (
        <iframe
            title="title"
            srcDoc={record[source]}
            height="800"
            width="98%"
            style={{ padding: 10, margin: 2, border: 0 }}
        />
    );
};

export default HelpCardItemShow;
