import Editor from "@monaco-editor/react";
import { useInput, Button } from "react-admin";
import React, { useState } from "react";

const MonacoEditor = (props) => {
    const {
        input: { name, onChange, ...rest },
        // meta: { touched, error },
        // isRequired,
    } = useInput(props);

    const [showPreview, setShowPreview] = useState(true);
    const handleTogglePreview = () => setShowPreview((p) => !p);

    return (
        <div>
            <Button
                color="primary"
                variant="contained"
                label={`open ${showPreview ? "show" : "editor"}`}
                onClick={handleTogglePreview}
                style={{ marginBottom: "18px" }}
            />
            {showPreview ? (
                <Editor
                    value={rest.value}
                    onChange={onChange}
                    height={props.height}
                    defaultLanguage="html"
                />
            ) : (
                <iframe
                    title="title"
                    srcDoc={rest.value}
                    height={props.height}
                    width="98%"
                    style={{ padding: 10, margin: 2, border: 0 }}
                />
            )}
        </div>
    );
};

export default MonacoEditor;

