import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    RichTextField,
    CreateButton,
    TopToolbar, TextInput, SelectInput, FilterButton,
} from "react-admin";
import Cache from "../utils/Cache";

const BonusDiscountList = (props) => {
    const allBonusCategories = Cache.get("allBonusCategories");
    const allBonusCities = Cache.get("allBonusCities");

    return (
        <List {...props}
          title="List of bonus discounts"
          actions={<ListActions/>}
          filters={bonusStatusFilter(allBonusCities, allBonusCategories)}
          sort={{field: "createdDate", order: "DESC"}}>
            <Datagrid>
                <TextField source="id"/>
                <RichTextField source="name" label="Name"/>
                <RichTextField source="numberDiscount" label="Number"/>
                <RichTextField source="address" label="Address"/>
                <RichTextField source="dateTitle" label="Date title"/>
                <RichTextField source="instagram" label="Instagram"/>
                <RichTextField source="phone" label="Phone"/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
        <FilterButton />
    </TopToolbar>
);

const bonusStatusFilter = (allBonusCities, allBonusCategories) => {
    return [
        <SelectInput source="bonusCityId" label="Bonus city" choices={allBonusCities} variant="outlined" />,
        <SelectInput source="bonusCategoryId" label="Bonus category" choices={allBonusCategories} variant="outlined" />
    ];
};

export default BonusDiscountList;

