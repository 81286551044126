import React from "react";
import { Edit, SimpleForm, TextInput, DateInput, SelectInput, useNotify, useRefresh, useRedirect } from "react-admin";
import LexicalEditor from "../utils/LexicalEditor";

const { TAG } = require("../utils/Constants");

const NewsEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`News updated`);
        redirect("/data-management/news");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    return (
        <Edit title="Edit a News" {...props} mutationMode="pessimistic" onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="id" disabled variant="outlined" />
                <TextInput source="title" multiline fullWidth variant="outlined" />
                <TextInput source="previewImage" multiline fullWidth variant="outlined" />
                <DateInput source="publishedDate" variant="outlined" />
                <SelectInput source="tag" choices={TAG} variant="outlined" />
                <LexicalEditor source="description" height={800} />
            </SimpleForm>
        </Edit>
    );
};

export default NewsEdit;

