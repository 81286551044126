import React from "react";
import {DeleteWithConfirmButton, FunctionField, Show, SimpleShowLayout, TextField, Toolbar} from "react-admin";
import Cache from "../utils/Cache";

const BonusDiscountShow = (props) => {
    const allBonusCategories = Cache.get("allBonusCategories");
    const allBonusCities = Cache.get("allBonusCities");

    return (
        <Show title="Show bonus discount" {...props}>
            <SimpleShowLayout>
                <TextField disabled source="id"/>
                <TextField source="name" label="Name"/>
                <TextField source="numberDiscount" label="Number discount"/>
                <TextField source="address" label="Address"/>
                <TextField source="dateTitle" label="Date title"/>
                <TextField source="instagram" label="Instagram"/>
                <TextField source="instruction" label="Instruction"/>
                <TextField source="moreInformation" label="More information"/>
                <TextField source="phone" label="Phone"/>
                <FunctionField label="Bonus city" render={record => allBonusCategories.find(bonusCategory => bonusCategory.id === record.bonusCategoryId).name}/>
                <FunctionField label="Bonus city" render={record => allBonusCities.find(bonusCity => bonusCity.id === record.bonusCityId).name}/>
                <Toolbar>
                    <DeleteWithConfirmButton/>
                </Toolbar>
            </SimpleShowLayout>
        </Show>
    );
};

export default BonusDiscountShow;
