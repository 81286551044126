import { theme } from 'innowise-ui-kit/dist/theme'
import { isRetinaScreen } from 'innowise-ui-kit/dist/theme/utils'
import { RETINA_SCREEN_MULTIPLIER } from 'innowise-ui-kit/dist/theme/settings'
import {
  H1_FONT_SIZE,
  H1_LINE_HEIGHT,
  H2_FONT_SIZE,
  H2_LINE_HEIGHT,
  H3_FONT_SIZE,
  H3_LINE_HEIGHT,
  H4_FONT_SIZE,
  H4_LINE_HEIGHT,
  BODY_BIG_FONT_SIZE,
  BODY_BIG_LINE_HEIGHT,
  BODY_MEDIUM_FONT_SIZE,
  BODY_MEDIUM_LINE_HEIGHT,
  BODY_SMALL_FONT_SIZE,
  BODY_SMALL_LINE_HEIGHT,
  INFO_MEDIUM_FONT_SIZE,
  INFO_MEDIUM_LINE_HEIGHT,
  INFO_SMALL_FONT_SIZE,
  INFO_SMALL_LINE_HEIGHT,
  SMALL_FONT_SIZE,
  SMALL_LINE_HEIGHT,
} from 'innowise-ui-kit/dist/theme/typography'

theme.spacing = factor => {
  const scale = isRetinaScreen ? RETINA_SCREEN_MULTIPLIER : 1

  if (typeof factor === 'undefined') {
    factor = 1
  }

  return `${Number((factor * 8 * scale).toFixed(1))}px`
}

theme.getPixelsSize = (name, addSuffix = true) => {
  const scale = isRetinaScreen
    ? RETINA_SCREEN_MULTIPLIER
    : 1

  const scaledPixelsSize = Number((name * scale).toFixed(1))

  return addSuffix ? `${scaledPixelsSize}px` : scaledPixelsSize
}

theme.typography = {
  ...theme.typography,
  h1: {
    ...theme.typography.h1,
    fontSize: theme.getPixelsSize(H1_FONT_SIZE),
    lineHeight: theme.getPixelsSize(H1_LINE_HEIGHT),
  },
  h2: {
    ...theme.typography.h2,
    fontSize: theme.getPixelsSize(H2_FONT_SIZE),
    lineHeight: theme.getPixelsSize(H2_LINE_HEIGHT),
  },
  h3: {
    ...theme.typography.h3,
    fontSize: theme.getPixelsSize(H3_FONT_SIZE),
    lineHeight: theme.getPixelsSize(H3_LINE_HEIGHT),
  },
  h4: {
    ...theme.typography.h4,
    fontSize: theme.getPixelsSize(H4_FONT_SIZE),
    lineHeight: theme.getPixelsSize(H4_LINE_HEIGHT),
  },
  bodyBig: {
    ...theme.typography.bodyBig,
    fontSize: theme.getPixelsSize(BODY_BIG_FONT_SIZE),
    lineHeight: theme.getPixelsSize(BODY_BIG_LINE_HEIGHT),
  },
  bodyMedium: {
    ...theme.typography.bodyMedium,
    fontSize: theme.getPixelsSize(BODY_MEDIUM_FONT_SIZE),
    lineHeight: theme.getPixelsSize(BODY_MEDIUM_LINE_HEIGHT),
  },
  bodySmall: {
    ...theme.typography.bodySmall,
    fontSize: theme.getPixelsSize(BODY_SMALL_FONT_SIZE),
    lineHeight: theme.getPixelsSize(BODY_SMALL_LINE_HEIGHT),
  },
  infoMedium: {
    ...theme.typography.infoMedium,
    fontSize: theme.getPixelsSize(INFO_MEDIUM_FONT_SIZE),
    lineHeight: theme.getPixelsSize(INFO_MEDIUM_LINE_HEIGHT),
  },
  infoSmall: {
    ...theme.typography.infoSmall,
    fontSize: theme.getPixelsSize(INFO_SMALL_FONT_SIZE),
    lineHeight: theme.getPixelsSize(INFO_SMALL_LINE_HEIGHT),
  },
  small: {
    ...theme.typography.small,
    fontSize: theme.getPixelsSize(SMALL_FONT_SIZE),
    lineHeight: theme.getPixelsSize(SMALL_LINE_HEIGHT),
  },
}

export default theme
