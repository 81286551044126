import React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton, FilterButton,
    List,
    RichTextField,
    SelectInput,
    ShowButton,
    TextField,
    TopToolbar,
} from "react-admin";
import Cache from "../utils/Cache";

const HelpCardItemList = (props) => {
    const allHelpStatus = Cache.get("allHelpStatus");

    return (
        <List {...props}
          title="List of help card items"
          actions={<ListActions/>}
          filters={helpStatusFilter(allHelpStatus)}
          sort={{field: "createdDate", order: "DESC"}}>
            <Datagrid>
                <TextField source="id"/>
                <RichTextField source="name" label="Name"/>
                <RichTextField source="number" label="Number"/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
        <FilterButton />
    </TopToolbar>
);

const helpStatusFilter = (allHelpStatus) => {
    return [<SelectInput source="helpCardId" label="HelpStatus" choices={allHelpStatus} variant="outlined" />];
};

export default HelpCardItemList;

