import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
} from "react-admin";

const BonusCityEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Bonus city edited`);
        redirect("/data-management/bonus-cities");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    return (
        <Edit title="Edit a bonus city" {...props} mutationMode="pessimistic" onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="id" disabled variant="outlined" />
                <TextInput source="city" fullWidth variant="outlined" />
                <TextInput source="subtitle" multiline fullWidth variant="outlined" />
                <TextInput source="imageUrl" multiline fullWidth variant="outlined" />
                <TextInput source="number" variant="outlined" />
            </SimpleForm>
        </Edit>
    );
};

export default BonusCityEdit;
