import React from 'react'
import { DecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'

import YouTubeComponent from './YouTubeComponent'


function convertIframeElement(domNode) {
  if (domNode instanceof HTMLIFrameElement) {
    const { id } = domNode;
    const node = $createYouTubeNode(id);
    return {node};
  }
  return null;
}

export class YouTubeNode extends DecoratorBlockNode {
  __id

  static getType() {
    return 'youtube'
  }

  static clone(node) {
    return new YouTubeNode(node.__id, node.__format, node.__key)
  }

  static importJSON(serializedNode) {
    const node = $createYouTubeNode(serializedNode.videoID)
    node.setFormat(serializedNode.format)
    return node
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'youtube',
      version: 1,
      videoID: this.__id,
    }
  }

  exportDOM() {
    const element = document.createElement('iframe')
    element.setAttribute('src', `https://www.youtube.com/embed/${this.__id}`)
    element.setAttribute('width', 560)
    element.setAttribute('height', 315)
    element.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture')
    element.setAttribute('allowFullScreen', true)
    element.setAttribute('title', 'YouTube video')
    element.setAttribute('id', this.__id)
    return {element}
  }

  static importDOM() {
    return {
      iframe: (node) => ({
        conversion: convertIframeElement,
        priority: 0,
      }),
    };
  }

  constructor(id, format, key) {
    super(format, key)
    this.__id = id
  }

  updateDOM() {
    return false
  }

  getId() {
    return this.__id
  }

  getTextContent(_includeInert, _includeDirectionless) {
    return `https://www.youtube.com/watch?v=${this.__id}`
  }

  decorate(_editor, config) {
    const embedBlockTheme = config.theme.embedBlock || {}
    const className = {
      base: embedBlockTheme.base || '',
      focus: embedBlockTheme.focus || '',
    }
    return (
      <YouTubeComponent
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        videoID={this.__id}
      />
    )
  }

  isInline() {
    return false
  }
}

export function $createYouTubeNode(videoID) {
  return new YouTubeNode(videoID)
}

export function $isYouTubeNode(node) {
  return node instanceof YouTubeNode
}
