import "./App.css";

import { Admin, fetchUtils, Resource } from "react-admin";
import FeedbackIcon from "@mui/icons-material/Feedback";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import BlockIcon from "@mui/icons-material/Block";
import EventNoteIcon from "@mui/icons-material/EventNote";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HelpIcon from '@mui/icons-material/Help';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import WorkIcon from '@mui/icons-material/Work';
import CategoryIcon from '@mui/icons-material/Category';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import { StylesProvider, ThemeProvider } from '@mui/styles'
import { theme } from 'innowise-ui-kit/dist/theme'

import springBootRestProvider from "./data/spring-data-provider";
import authProvider from "./data/auth-provider";
import keycloak from "./components/utils/Keycloak";
import FeedbackList from "./components/feedback/FeedbackList";
import FeedbackShow from "./components/feedback/FeedbackShow";
import NewsList from "./components/news/NewsList";
import NewsCreate from "./components/news/NewsCreate";
import NewsEdit from "./components/news/NewsEdit";
import NewsShow from "./components/news/NewsShow";
import EventList from "./components/calendar_event/EventList";
import EventCreate from "./components/calendar_event/EventCreate";
import EventEdit from "./components/calendar_event/EventEdit";
import EventShow from "./components/calendar_event/EventShow";
import ForbiddenPage from "./components/utils/ForbiddenPage";
import VersionsList from "./components/app_version/VersionsList";
import VersionCreate from "./components/app_version/VersionCreate";
import VersionEdit from "./components/app_version/VersionEdit";
import QuestionnaireCreate from "./components/questionnaire/QuestionnaireCreate";
import QuestionnaireList from "./components/questionnaire/QuestionnaireList";
import QuestionnaireEdit from "./components/questionnaire/QuestionnaireEdit";
import QuestionnaireShow from "./components/questionnaire/QuestionnaireShow";
import BonusCityList from "./components/bonus_city/BonusCityList";
import BonusCityCreate from "./components/bonus_city/BonusCityCreate";
import BonusCityEdit from "./components/bonus_city/BonusCityEdit";
import BonusCityShow from "./components/bonus_city/BonusCityShow";
import BonusCategoryList from "./components/bonus_category/BonusCategoryList";
import BonusCategoryCreate from "./components/bonus_category/BonusCategoryCreate";
import BonusCategoryEdit from "./components/bonus_category/BonusCategoryEdit";
import BonusCategoryShow from "./components/bonus_category/BonusCategoryShow";
import BonusDiscountList from "./components/bonus_discount/BonusDiscountList";
import BonusDiscountCreate from "./components/bonus_discount/BonusDiscountCreate";
import BonusDiscountEdit from "./components/bonus_discount/BonusDiscountEdit";
import BonusDiscountShow from "./components/bonus_discount/BonusDiscountShow";
import HelpCardItemList from "./components/help_card_item/HelpCardItemList";
import HelpCardItemCreate from "./components/help_card_item/HelpCardItemCreate";
import HelpCardItemEdit from "./components/help_card_item/HelpCardItemEdit";
import HelpCardItemShow from "./components/help_card_item/HelpCardItemShow";
import HelpCardList from "./components/help_card/HelpCardList";
import HelpCardCreate from "./components/help_card/HelpCardCreate";
import HelpCardEdit from "./components/help_card/HelpCardEdit";
import HelpCardShow from "./components/help_card/HelpCardShow";
import ReferralList from "./components/referral/ReferralList";
import ReferralCreate from "./components/referral/ReferralCreate";
import ReferralEdit from "./components/referral/ReferralEdit";
import ReferralShow from "./components/referral/ReferralShow";

const {DATA_PROVIDER_URL} = require("./components/utils/Constants");

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: "application/json"});
    }

    options.user = {
        authenticated: true,
        token: `Bearer ${keycloak.token}`,
    };

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = springBootRestProvider(DATA_PROVIDER_URL, httpClient);

const generateClassName = (rule, sheet) => `${sheet.options.classNamePrefix}-${rule.key}`

function App() {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Admin dataProvider={dataProvider} authProvider={authProvider} loginPage={false}>
                    {(permissions) =>
                        permissions.includes("ITS_MOBILE_ADMIN") ||
                        permissions.includes("FEEDBACK_ADMIN") ||
                        permissions.includes("NEWS_ADMIN") ||
                        permissions.includes("EVENT_ADMIN") ||
                        permissions.includes("APP_VERSION_ADMIN")
                            ? [
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/feedbacks"
                                            options={{label: "Feedbacks"}}
                                            list={FeedbackList}
                                            show={FeedbackShow}
                                            icon={FeedbackIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/news"
                                            options={{label: "News"}}
                                            list={NewsList}
                                            create={NewsCreate}
                                            edit={NewsEdit}
                                            show={NewsShow}
                                            icon={NewspaperIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/events"
                                            options={{label: "Events"}}
                                            list={EventList}
                                            create={EventCreate}
                                            edit={EventEdit}
                                            show={EventShow}
                                            icon={EventNoteIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/questionnaires"
                                            options={{label: "Questionnaires"}}
                                            list={QuestionnaireList}
                                            create={QuestionnaireCreate}
                                            edit={QuestionnaireEdit}
                                            show={QuestionnaireShow}
                                            icon={QuestionMarkIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/bonus-cities"
                                            options={{label: "Bonus cities"}}
                                            list={BonusCityList}
                                            create={BonusCityCreate}
                                            edit={BonusCityEdit}
                                            show={BonusCityShow}
                                            icon={ApartmentIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/bonus-categories"
                                            options={{label: "Bonus categories"}}
                                            list={BonusCategoryList}
                                            create={BonusCategoryCreate}
                                            edit={BonusCategoryEdit}
                                            show={BonusCategoryShow}
                                            icon={CategoryIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/bonus-discounts"
                                            options={{label: "Bonus discounts"}}
                                            list={BonusDiscountList}
                                            create={BonusDiscountCreate}
                                            edit={BonusDiscountEdit}
                                            show={BonusDiscountShow}
                                            icon={CardGiftcardIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/help-cards"
                                            options={{label: "Help cards"}}
                                            list={HelpCardList}
                                            create={HelpCardCreate}
                                            edit={HelpCardEdit}
                                            show={HelpCardShow}
                                            icon={HelpIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/help-card-items"
                                            options={{label: "Help items"}}
                                            list={HelpCardItemList}
                                            create={HelpCardItemCreate}
                                            edit={HelpCardItemEdit}
                                            show={HelpCardItemShow}
                                            icon={HelpCenterIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("NEWS_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/referrals"
                                            options={{label: "Referrals"}}
                                            list={ReferralList}
                                            create={ReferralCreate}
                                            edit={ReferralEdit}
                                            show={ReferralShow}
                                            icon={WorkIcon}
                                        />,
                                    ]
                                    : null,
                                permissions.includes("APP_VERSION_ADMIN") || permissions.includes("ITS_MOBILE_ADMIN")
                                    ? [
                                        <Resource
                                            name="data-management/versions"
                                            options={{label: "App versions"}}
                                            list={VersionsList}
                                            create={VersionCreate}
                                            edit={VersionEdit}
                                            icon={InstallMobileIcon}
                                        />,
                                    ]
                                    : null,
                            ]
                            : [
                                <Resource
                                    name="forbidden"
                                    list={ForbiddenPage}
                                    icon={BlockIcon}
                                    options={{label: "No access"}}
                                />,
                            ]
                    }
                </Admin>
            </ThemeProvider>
        </StylesProvider>
    );
}

export default App;

