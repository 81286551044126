export const CAN_USE_DOM =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined';

export const generateHtmlString = (styles = '', body = '')=> {
  return (
    `<!DOCTYPE html>
        <html lang="en">
          <head>
            <link href='https://fonts.googleapis.com/css?family=Manrope' rel='stylesheet'>
            <meta name="viewport" content="width=device-width,initial-scale=1.0">
            <style type="text/css">
              ${styles}
              @media screen and (max-width: 900px) {
                img {
                  width: 100%;
                }
          
                iframe {
                  width: 100%;
                  height: 100%;
                  aspect-ratio: 16/9;
                }
              }
            </style>
          </head>
          <body>
            ${body}
          </body>
        </html>
      `
  )
}