import * as constants from '../constants/styles'

const generateTheme = (classes) => ({
  ltr: classes[constants.LTR_STYLE_NAME],
  rtl: classes[constants.RTL_STYLE_NAME],
  paragraph: classes[constants.INFO_MEDIUM_TEXT_STYLE_NAME],
  quote: classes[constants.QUOTE_TEXT_STYLE_NAME],
  heading: {
    h1: classes[constants.H1_STYLE_NAME],
    h2: classes[constants.H2_STYLE_NAME],
    h3: classes[constants.H3_STYLE_NAME],
    h4: classes[constants.H4_STYLE_NAME],
  },
  list: {
    nested: {
      listitem: classes[constants.NESTED_LISTITEM_STYLE_NAME]
    },
    ol: classes[constants.LIST_OL_STYLE_NAME],
    ul: classes[constants.LIST_UL_STYLE_NAME],
    listitem: classes[constants.LISTITEM_STYLE_NAME]
  },
  link: classes[constants.LINK_STYLE_NAME],
  image: 'editor-image',
  text: {
    bold: classes[constants.BOLD_TEXT_STYLE_NAME],
    italic: classes[constants.ITALIC_TEXT_STYLE_NAME],
    overflowed: classes[constants.OVERFLOWED_TEXT_STYLE_NAME],
    hashtag: classes[constants.HASHTAG_TEXT_STYLE_NAME],
    underline: classes[constants.UNDERLINE_TEXT_STYLE_NAME],
    strikethrough: classes[constants.STRIKETHROUGH_TEXT_STYLE_NAME],
    underlineStrikethrough: classes[constants.UNDERLINE_STRIKETHROUGH_TEXT_STYLE_NAME],
  },
  embedBlock: {
    base: 'embed-block',
    focus: 'embed-block-focus',
  }
})

export default generateTheme;
