import { createStyles } from '@mui/styles'

import * as constants from './constants/styles'

const resetingStyles = {
  margin: 0,
  padding: 0
}

const exportingStyles =  createStyles(theme => {
  const extraTypographyStyles = { ...resetingStyles }

  return {
    body: {
      // '-webkit-text-size-adjust': '100%',
    },

    // positioning
    [constants.LTR_STYLE_NAME]: { textAlign: 'left' },
    [constants.RTL_STYLE_NAME]: { textAlign: 'right' },

    // typography
    [constants.H1_STYLE_NAME]: { ...theme.typography.h1, ...extraTypographyStyles },
    [constants.H2_STYLE_NAME]: { ...theme.typography.h2, ...extraTypographyStyles },
    [constants.H3_STYLE_NAME]: { ...theme.typography.h3, ...extraTypographyStyles },
    [constants.H4_STYLE_NAME]: { ...theme.typography.h4, ...extraTypographyStyles },
    [constants.BODY_BIG_TEXT_STYLE_NAME]: { ...theme.typography.bodyBig, ...extraTypographyStyles },
    [constants.BODY_MEDIUM_TEXT_STYLE_NAME]: { ...theme.typography.bodyMedium, ...extraTypographyStyles },
    [constants.BODY_SMALL_TEXT_STYLE_NAME]: { ...theme.typography.bodySmall, ...extraTypographyStyles },
    [constants.INFO_MEDIUM_TEXT_STYLE_NAME]: { ...theme.typography.infoMedium, ...extraTypographyStyles },
    [constants.INFO_SMALL_TEXT_STYLE_NAME]: { ...theme.typography.infoSmall, ...extraTypographyStyles },
    [constants.SMALL_TEXT_STYLE_NAME]: { ...theme.typography.small, ...extraTypographyStyles },
    [constants.QUOTE_TEXT_STYLE_NAME]: {
      ...theme.typography.bodyMedium,
      ...extraTypographyStyles,
      marginLeft: theme.spacing(3),
      color: theme.palette.grey['40'],
      borderLeftColor:  theme.palette.grey['15'],
      borderLeftWidth: theme.spacing(0.5),
      borderLeftStyle: 'solid',
      paddingLeft: theme.spacing(2),
    },

    // font-styles
    [constants.BOLD_TEXT_STYLE_NAME]: { fontWeight: 'bold' },
    [constants.ITALIC_TEXT_STYLE_NAME]: { fontStyle: 'italic' },
    [constants.OVERFLOWED_TEXT_STYLE_NAME]: {},
    [constants.HASHTAG_TEXT_STYLE_NAME]: {},
    [constants.UNDERLINE_TEXT_STYLE_NAME]: { textDecoration: 'underline' },
    [constants.STRIKETHROUGH_TEXT_STYLE_NAME]: { textDecoration: 'line-through' },
    [constants.UNDERLINE_STRIKETHROUGH_TEXT_STYLE_NAME]: { textDecoration: 'underline line-through' },

    // link
    [constants.LINK_STYLE_NAME]: {
      textDecoration: 'none',
      color: theme.palette.action.main,
    },
    
    // list
    [constants.NESTED_LISTITEM_STYLE_NAME]: { ...theme.typography.infoMedium, listStyleType: 'none' },
    [constants.LIST_OL_STYLE_NAME]: { ...theme.typography.infoMedium, padding: 0, margin: 0, marginLeft: theme.spacing(2) },
    [constants.LIST_UL_STYLE_NAME]: { ...theme.typography.infoMedium, padding: 0, margin: 0, marginLeft: theme.spacing(2) },
    [constants.LISTITEM_STYLE_NAME]: { ...theme.typography.infoMedium, margin: `${theme.spacing(1)} ${theme.spacing(4)}` },
  }
})

export default exportingStyles
