import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    TopToolbar,
    CreateButton,
    RichTextField,
    SelectInput,
    FilterButton,
    SelectField,
    DateField,
} from "react-admin";

const { NEWS_STATUS } = require("../utils/Constants");

const NewsList = (props) => (
    <List
        {...props}
        title="List of news"
        actions={<ListActions />}
        filters={newsStatusFilter()}
        sort={{ field: "publishedDate", order: "DESC" }}
    >
        <Datagrid>
            <TextField source="id" />
            <RichTextField source="title" label="News" />
            <SelectField source="newsStatus" choices={NEWS_STATUS} label="Status" />
            <DateField source="publishedDate" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
        <FilterButton />
    </TopToolbar>
);

const newsStatusFilter = () => {
    return [<SelectInput source="newsStatus" label="Status" choices={NEWS_STATUS} variant="outlined" />];
};

export default NewsList;

