import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    RichTextField,
    CreateButton,
    TopToolbar,
    DateField,
} from "react-admin";

const QuestionnaireList = (props) => (
    <List {...props}
          title="List of questionnaires"
          actions={<ListActions />}
          sort={{ field: "createdDate", order: "DESC" }}>
        <Datagrid>
            <TextField source="id" />
            <RichTextField source="title" label="Questionnaire" />
            <RichTextField source="link" label="Link" />
            <DateField source="createdDate" showTime label="Date" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

export default QuestionnaireList;

