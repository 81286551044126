import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
} from "react-admin";

const BonusCityCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Bonus city saved`);
        redirect("/data-management/bonus-cities");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, { type: "warning" });
    };

    return (
        <Create title="Create a bonus city" {...props} onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="city" fullWidth variant="outlined" />
                <TextInput source="subtitle" multiline fullWidth variant="outlined" />
                <TextInput source="imageUrl" multiline fullWidth variant="outlined" />
            </SimpleForm>
        </Create>
    );
};

export default BonusCityCreate;
