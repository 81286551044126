import React from "react";
import {
    DateTimeInput,
    Edit,
    SelectInput,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    useNotify,
    useRedirect,
    useRefresh,
} from "react-admin";
import Cache from "../utils/Cache";
import LexicalEditor from '../utils/LexicalEditor';
import {MONTHS} from "../utils/Constants";

const {EVENT_FORMAT, TAG} = require("../utils/Constants");

const EventEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Event edited`);
        redirect("/data-management/events");
        refresh();
    };

    const onFailure = (error) => {
        notify(`${error.body}`, {type: "warning"});
    };

    const allCities = Cache.get("allCities");

    return (
        <Edit title="Edit a event" {...props} mutationMode="pessimistic" onSuccess={onSuccess} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="id" disabled variant="outlined"/>
                <TextInput source="title" multiline fullWidth variant="outlined"/>
                <TextInput source="previewImage" multiline fullWidth variant="outlined"/>
                <SimpleShowLayout className='main_block'>
                    <TextField source="Выберите одно из двух"/>
                    <SimpleShowLayout className='inputs_block'>
                        <DateTimeInput source="eventDateTime" variant="outlined"/>
                        <SelectInput source="monthId" choices={MONTHS}/>
                    </SimpleShowLayout>
                </SimpleShowLayout>
                <SelectInput
                    source="town"
                    choices={allCities}
                    allowEmpty
                    emptyText="Все города"
                    emptyValue={undefined}
                    resettable
                    variant="outlined"
                />
                <SelectInput source="tag" choices={TAG} variant="outlined"/>
                <SelectInput source="eventFormat" choices={EVENT_FORMAT} variant="outlined"/>
                <LexicalEditor source="description"/>
                <TextInput source="location" multiline fullWidth variant="outlined"/>
                <TextInput source="linkForRegistration" multiline fullWidth variant="outlined"/>
            </SimpleForm>
        </Edit>
    );
};

export default EventEdit;
