import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    TopToolbar,
    CreateButton,
    BulkDeleteWithConfirmButton
} from "react-admin";

const ReferralList = (props) => {
    return (
        <List
            {...props}
            title="List of Referrals"
            actions={<ListActions />}
            bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
            <Datagrid>
                <TextField source="id" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

export default ReferralList;
